<template>
    <a-modal v-model="modalVisible" title="任务发布" @cancel="OnClose">
        <a-button type="primary" ghost  @click="selecttype(1)" style="margin-right:10px;">
            店铺
        </a-button>
        <a-button type="primary" ghost @click="selecttype(2)" style="margin-right:10px;">
            软文
        </a-button>
        <a-button  type="primary" ghost @click="selecttype(0)" style="margin-right:10px;">
            H5链接
        </a-button>
        <div slot="footer"></div>
    </a-modal>
</template>
<script>
    export default {
        name: "ActivityType",
        data() {
            return {
                modalVisible: false,
            };
        },
        props: {//组件属性
            Show: Boolean
        },
        methods: {//方法
            selecttype(e) {
                var self = this;
                self.$router.push({
                    name: "Activity_Save",
                    query: {SceneType: e }
                });
            },
            OnClose() {
                this.$emit("hide");
            }
        },
          watch: {//侦听属性
              Show: function (val) {
                  this.modalVisible = val;
                  console.log(this.modalVisible)
              }
        },
        computed: {//计算属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>