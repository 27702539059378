 <!---活动-->
<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="margin-top:12px;min-height: calc(100% - 36px);background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                    <a-layout style="height:100%;background:#fff;">
                        <a-layout-header style="height:auto;background:#fff;padding:0 20px  ;">
                            <a-row>
                                <a-col :span="20">
                                    <a-form layout="horizontal" style="padding:10px  20px 0px;height:auto;">
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="状态">
                                                    <a-radio-group default-value="PublishStatus" v-model="PublishStatus" @change="QueryData">
                                                        <a-radio-button value="-1">全部</a-radio-button>
                                                        <a-radio-button value="0">未发布</a-radio-button>
                                                        <a-radio-button value="1">已发布</a-radio-button>
                                                        <a-radio-button value="2">已结束</a-radio-button>

                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="场景">
                                                    <a-radio-group default-value="SceneType" v-model="SceneType" @change="QueryData">
                                                        <a-radio-button value="-1">全部</a-radio-button>
                                                        <a-radio-button value="1">店铺</a-radio-button>
                                                        <a-radio-button value="2">软文</a-radio-button>
                                                        <a-radio-button value="0">H5连接</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>

                                        <a-form-item label="" style="margin-bottom:0px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="活动">
                                                    <a-input placeholder="输入活动名称" v-model="Name" :value="Name" style="width:278px;" />
                                                </a-form-item>

                                                <a-form-item label="">
                                                    <a-button type="primary" class="margin-r" @click="QueryData">
                                                        查询
                                                    </a-button>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                    </a-form>
                                </a-col>
                                <a-col :span="4" class="textal_r">
                                    <a-button type="primary" @click="addActivity()">创建活动</a-button>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-table   bordered :columns="columns" rowKey="ID" :data-source="Activitys" :pagination="pagination" @change="pagerChange" style="padding:14px 20px;" class="yj-table-post">
                            <div class="yj_lists_commodity" slot="message" slot-scope="text, record">

                                <div class="yj_lists_commodity_text">
                                    <div> {{record.Name}}</div>
                                    <div> {{record.DisplayStatus}}</div>
                                </div>
                            </div>

                            <span slot="SceneType" slot-scope="text,record">{{ShowScene(record.SceneType)}}</span>

                            <span slot="PublishStatus" slot-scope="text,record">
                                {{ShowPublishStatus(record.PublishStatus)}}
                            </span>
                            <span slot-scope="text,record" slot="ReadSecond">
                                <span>{{record.ReadSecond}}</span>
                            </span>
                            <span slot="BudgetMoney" slot-scope="text, record">
                                ￥{{record.BudgetMoney}}
                            </span>
                            <span slot="HasReadMoney" slot-scope="text, record">
                                {{record.HasReadCount}}(￥{{record.HasReadMoney}})
                            </span>
                            <span slot="HasShareMone" slot-scope="text, record">
                                {{record.HasShareCount}}(￥{{record.HasShareMone}})
                            </span>
                            <div slot="action" slot-scope="text, record">
                                <a-button v-if="record.PublishStatus ==0" type="link" size="small" @click="publishActivity(record.ID)"> 发布</a-button>
                                <a-button v-if="record.PublishStatus == 1" type="link" size="small" @click="cancleActivity(record.ID)">下线</a-button>
                                <a-button v-if="record.PublishStatus == 1" type="link" size="small" @click="viewActitity(record)">预览</a-button>
                                
                                <a-button v-if="record.PublishStatus == 0" type="link" size="small" @click="ActivityDetail(record.ID,record.SceneType,0)"> 编辑</a-button>
                                <a-button type="link" size="small" @click="ActivityDetail(record.ID,record.SceneType,1)">查看</a-button>
                                <a-button v-if="record.PublishStatus != 0" type="link" size="small" @click="AwardStatistics(record.ID)"> 明细</a-button>
                            </div>
                        </a-table>
                    </a-layout>

                </div>
            </a-layout-content>
        </a-layout>
        <a-modal v-model="ViewmodalVisible" title="微信扫码预览"
                 :maskClosable="false">
            <div style="text-align:center;">
                <img :src="viewImgUrl" height="360" />
            </div>
            <div slot="footer"></div>
        </a-modal>
        <ActivityType v-bind:Show="modalVisible" v-on:hide="modalVisible=false"></ActivityType>
    </div>
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import ActivityType from "@/Views/MarketingModule/Activity/List/components/ActivityType"
 
 
    export default {
        name: "Marketing_Activity_List",
        data() {
            return {
                Activitys: [],
                columns: [
                    {
                        title: "活动信息",
                        scopedSlots: { customRender: 'message' },
                        width: 120
                    },
                    {
                        title: "活动场景",
                        scopedSlots: { customRender: 'SceneType' },
                        width: 200,

                    },
                    {
                        title: "活动状态",
                        scopedSlots: { customRender: 'PublishStatus' },
                        width: 200,

                    },
                    {
                        title: "预算",
                        scopedSlots: { customRender: 'BudgetMoney' },
                        width: 200,

                    },
                    {
                        title: "已阅读",
                        scopedSlots: { customRender: 'HasReadMoney' },
                        width: 200,
                    },
                    {
                        title: "已分享",
                        scopedSlots: { customRender: 'HasShareMone' },
                        width: 200,
                    },
                    {
                        title: "操作",
                        align: "center",
                        scopedSlots: { customRender: 'action' },
                    },
                ],
                modalVisible: false, //创建活动类型模态框
                ViewmodalVisible: false, //预览模态框
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                viewImgUrl: "",//活动二维码
                ID: "",//活动ID
                title: "",//活动标题
                SceneType: "-1",
                Name: "",
                ArticleTargetUrl:"",//活动目标链接
                PublishStatus: "-1",
                PublishStatusEnum: [],//发布状态
                PartStatusEnum: [],//参与状态
                PartTypeEnum: [],//参与类型
                SceneEnum: [],//场景类型
                AwardEnum: [],//奖励类型
                FinishStatusEnum: []//完成状态
            };
        },
        methods: {
            //数据统计
            AwardStatistics(ID) {
                this.$router.push({
                    name: "Activity_Detail", query: {
                        ID: ID,
                    }
                })
            },
            //推送
            sendActivity(e) {
                var self = this;
               this.$confirm({
                    title: '提示',
                    content: '是否向您的所有合伙人推送【' + e.Name + '】活动',
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/Activity/SendTemplateMessage",
                            data: {
                                ArtID: e.SceneIdentify,
                                sharetitle: e.Name,
                                type: e.SceneType,
                                targetUrl: e.SceneURL
                            },
                            OnSuccess: function () {
                                self.$message.success("推送成功");
                            }
                        }
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            
            //预览
            viewActitity(e) {
                console.log(e)
                this.ViewmodalVisible = true;
                this.getStoreView(e);
            },
             getStoreView(e) {
                var self = this;
                var op = {
                    url: "/MarketingModule/Activity/PreView",
                    data: {
                        ActivityID: e.ID
                        //sharetitle: e.Name,
                        //type: e.SceneType,
                        //targetUrl:e.SceneURL
                    },
                    OnSuccess: function (data) {                       
                        self.viewImgUrl = data.data;
                    }
                };
                http.Post(op);
            },
            //创建新活动
            addActivity() {
                this.modalVisible = true;
            },

            //发布活动
            publishActivity(ID) {
                console.log(ID)
                var self = this;
                var op = {
                    url: "/MarketingModule/Activity/PublishActivity",
                    data: {
                        ActivityID: ID
                    },
                    OnSuccess: function () {
                        self.$message.success("发布成功");
                        self.loadData();
                    }
                }
                http.Post(op);
            },
            //编辑活动
            ActivityDetail(ID, SceneType, Disable) {
                this.$router.push({
                    name: "Activity_Save", query: {
                        ID: ID,
                        SceneType: SceneType,
                        Disable: Disable
                    }
                })
            },
            //下线活动
            cancleActivity(ID) {
                var self = this;
                var op = {
                    url: "/MarketingModule/Activity/CancleActivity",
                    data: {
                        ActivityID: ID
                    },
                    OnSuccess: function () {
                        self.$message.success("下线成功");
                        self.loadData();
                    }
                }
                http.Post(op);
            },
            //查询事件
            QueryData() {
                this.loadData();
            },
            //页面跳转事件

            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },


            //加载活动列表
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Activity/GetActivityListByCustomID",
                    data: {
                        PageIndex: self.pagination.current,
                        PublishStatus: self.PublishStatus,
                        SceneType: self.SceneType,
                        Name: self.Name
                    },
                    OnSuccess: function (res) {
                        res.data.Data.forEach(element => {
                            element.ReadMoney = parseFloat(element.ReadMoney).toFixed(2);
                            element.ShareMoney = parseFloat(element.ShareMoney).toFixed(2);
                            element.PersonalMoneyMax = parseFloat(element.PersonalMoneyMax).toFixed(2);
                            element.BudgetMoney = parseFloat(element.BudgetMoney).toFixed(2);
                            element.HasShareMone = parseFloat(element.HasShareMone).toFixed(2);
                            element.HasReadMoney = parseFloat(element.HasReadMoney).toFixed(2);
                        });
                        self.Activitys = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;

                    }
                };
                http.Post(op);
            },
            //获取发布状态枚举
            getPublishStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumActivityPublishStatus"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.PublishStatusEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowPublishStatus: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.PublishStatusEnum[Key])
                    return Self.PublishStatusEnum[Key].Name
                else
                    return "";
            },
            //获取参与状态枚举
            getPartStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumActivityPartStatus"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.PartStatusEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowPartStatus: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.PartStatusEnum[Key])
                    return Self.PartStatusEnum[Key].Name
                else
                    return "";
            },
            //获取参与类型枚举
            getPartTypeEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumActivityPartType"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.PartTypeEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowPartType: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.PartTypeEnum[Key])
                    return Self.PartTypeEnum[Key].Name
                else
                    return "";
            },
            //获取场景枚举
            getSceneEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumActivityScene"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.SceneEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowScene: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.SceneEnum[Key])
                    return Self.SceneEnum[Key].Name
                else
                    return "";
            },
            //获取奖励类型枚举
            getAwardEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumAwardType"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.AwardEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowAward: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.AwardEnum[Key])
                    return Self.AwardEnum[Key].Name
                else
                    return "";
            },
            //获取任务完成状态
            getFinishStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumFinishStatus"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.FinishStatusEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowFinishStatus: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.FinishStatusEnum[Key])
                    return Self.FinishStatusEnum[Key].Name
                else
                    return "";
            },

        },
        components: {
            ActivityType
        },
        mounted() {
            this.loadData();
            this.getPublishStatusEnum();
            this.getSceneEnum();
        }
    }
</script>
<style type="text/css">
    .yj-lists_task_mark_1 {
        background-color: #0094ff;
    }

    .yj-lists_task_mark {
        position: absolute;
        right: -20px;
        top: 11px;
        padding: 2px 22px;
        transform: rotate(48deg);
        background-color: #85BC22;
        font-size: 0.76rem;
        color: #fff;
    }

    .yj-table-post table tr:first-child {
        height: 54px;
    }

    .yj-table-post table tr {
        min-height: 110px;
        height: 110px
    }

        .yj-table-post table tr td:last-child {
            position: relative;
            overflow: hidden;
        }
</style>



